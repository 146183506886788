var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "Layout",
    [
      _c("PageHeader", { attrs: { title: _vm.title, items: _vm.items } }),
      _c("div", { staticClass: "row" }, [
        _c("div", { staticClass: "col-12" }, [
          _c(
            "div",
            { staticClass: "accordion mb-2", attrs: { role: "tablist" } },
            [
              _c(
                "b-card",
                { staticClass: "mb-1", attrs: { "no-body": "" } },
                [
                  _c(
                    "b-card-header",
                    {
                      staticClass: "p-1",
                      attrs: { "header-tag": "header", role: "tab" }
                    },
                    [
                      _c(
                        "b-button",
                        {
                          staticStyle: {
                            background: "#e5e9f0",
                            color: "black !important",
                            border: "none !important"
                          },
                          attrs: { block: "" },
                          on: {
                            click: function($event) {
                              _vm.active = !_vm.active
                            }
                          }
                        },
                        [
                          _c("div", { staticClass: "row" }, [
                            _c("div", { staticClass: "col text-left mt-1" }, [
                              _c("i", {
                                staticClass: "mdi mdi-format-list-bulleted"
                              }),
                              _vm._v(
                                " Liste Ordres de Service (Entretien & Nettoyage)"
                              )
                            ]),
                            _c("div", { staticClass: "col text-right" }, [
                              _vm.active
                                ? _c("i", {
                                    staticClass:
                                      "mdi mdi-chevron-up font-size-18"
                                  })
                                : _c("i", {
                                    staticClass:
                                      "mdi mdi-chevron-down font-size-18"
                                  })
                            ])
                          ])
                        ]
                      )
                    ],
                    1
                  ),
                  _c(
                    "b-collapse",
                    {
                      attrs: {
                        id: "accordion-1",
                        visible: _vm.active,
                        accordion: "my-accordion",
                        role: "tabpanel"
                      }
                    },
                    [
                      _c("b-card-body", { staticClass: "shadow-lg" }, [
                        _c(
                          "div",
                          { staticClass: "table-responsive mb-0 shadow" },
                          [
                            _c(
                              "v-table",
                              {
                                staticClass: "table table-hover",
                                attrs: {
                                  data: _vm.List_ODS,
                                  selectionMode: "single",
                                  selectedClass: "table-info"
                                },
                                on: {
                                  selectionChanged: function($event) {
                                    _vm.selectedRows = $event
                                  }
                                },
                                scopedSlots: _vm._u([
                                  {
                                    key: "body",
                                    fn: function(ref) {
                                      var displayData = ref.displayData
                                      return _c(
                                        "tbody",
                                        {},
                                        _vm._l(displayData, function(row) {
                                          return _c(
                                            "v-tr",
                                            {
                                              key: row.id,
                                              attrs: { row: row }
                                            },
                                            [
                                              _c("td", [
                                                _vm._v(
                                                  " " + _vm._s(row.ID_ODS) + " "
                                                )
                                              ]),
                                              _c("td", [
                                                _vm._v(_vm._s(row.Bdv))
                                              ]),
                                              _c("td", [
                                                _vm._v(_vm._s(row.ID_CTT))
                                              ]),
                                              _c("td", [
                                                _vm._v(_vm._s(row.prestataire))
                                              ]),
                                              _c("td", [
                                                _vm._v(_vm._s(row.date_ODS))
                                              ]),
                                              _c("td", [
                                                _vm._v(_vm._s(row.date_execODS))
                                              ])
                                            ]
                                          )
                                        }),
                                        1
                                      )
                                    }
                                  }
                                ])
                              },
                              [
                                _c(
                                  "thead",
                                  {
                                    staticClass: "thead-light",
                                    attrs: { slot: "head" },
                                    slot: "head"
                                  },
                                  [
                                    _c("tr", [
                                      _c("th", [_vm._v("N° ODS")]),
                                      _c("th", [_vm._v("Base de Vie")]),
                                      _c("th", [_vm._v("N° Contrat")]),
                                      _c("th", [_vm._v("Prestataire")]),
                                      _c("th", [_vm._v("Date ODS")]),
                                      _c("th", [_vm._v("Date Exécution")])
                                    ])
                                  ]
                                )
                              ]
                            )
                          ],
                          1
                        )
                      ])
                    ],
                    1
                  )
                ],
                1
              )
            ],
            1
          ),
          _c(
            "div",
            { staticClass: "card mt-3" },
            [
              _c("ODSform", {
                attrs: { base: _vm.selectedRows },
                on: {
                  odsvalues: function($event) {
                    _vm.ODS = $event
                  }
                }
              })
            ],
            1
          )
        ])
      ])
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }