<script>
import appConfig from "@/app.config";
import { FormWizard, TabContent } from "vue-form-wizard";
import Swal from "sweetalert2";
// import Switches from "vue-switches";
// import repas from "./repas";


export default {
    page: {
        title: "Ajout Nouvelle Base de Vie",
        meta: [{ name: "description", content: appConfig.description}],
    },
    // import : { fetchSettings },
    components: {/*Switches,*/ FormWizard,TabContent},
    props:{
      base:{
        type:Array,
        default:undefined
      }
    },
    data() {
      return {
      tableData: [],
      title: "Ordre de Service",
      selectedBdv:0,
      restaurants:[],
      ODS_info: {
        ID_ODS:'',
        Bdv:0,
        ID_CTT:'',
        prestataire:0,
        date_ODS:'',
        date_exec_ODS:'',
        period:'',
        comments:''
      },
      fields:[
        { region: "", site: "", zone: "", bloc: "" },
      ]

    };
  },
  watch:{

  },
  mounted(){
    this.ODS_info.date_ODS = this.TodayDate();
    this.ODS_info.date_exec_ODS = this.TodayDate();
  },

  methods:{
    onComplete(){
      // this.$refs.principal.nextTab();
      this.$emit('odsvalues',this.ODS_info);
      // if (this.ODS_info.breakfast) this.$emit('odsbreak',this.ODS_breakfast);
      // if (this.ODS_info.lunch) this.$emit('odslunch',this.ODS_lunch);
      // if (this.ODS_info.dinner) this.$emit('odsdinner',this.ODS_dinner);
      // if (this.ODS_info.others) this.$emit('odsothers',this.ODS_breakfast);
      Swal.fire("Félicitations!", "ODS "+this.ODS_info.ID_ODS, "success");
      this.resetData();
      
    },
    TodayDate(){
        var td, dt = new Date;
        td = dt.getFullYear()+"-"+(dt.getMonth()+1 < 10 ? "0"+(dt.getMonth()+1): dt.getMonth()+1)+"-"+(dt.getDate()<10 ? "0"+dt.getDate() : dt.getDate()) ;

        return td;
    },
    AddformData() {
      this.fields.push({ region: "", site: "", zone: "", bloc: "" });
    },
    deleteRow(index) {
      if (confirm("Are you sure you want to delete this element?"))
        this.fields.splice(index, 1);
    },
  }
    
}
</script>

<template>
    <!-- <Layout>
    <PageHeader :title="title" :items="items" /> -->
    <div class="row">
      <div class="col-12">
        <!-- <b-tabs content-class="mt-0">
          <b-tab title="Informations Générales" active> -->
                <div class="card">
                <div class="card-body">
                  <!-- <h4 class="card-title">New </h4> -->
                  <form-wizard class="wiz" color="#556ee6" @on-complete="onComplete">
                    <tab-content title="Informations Contractuels" icon="fas fa-file-contract" :selected="true" >
                      <b-alert show variant="info"> <i class="mdi mdi-information-outline"></i> Les champs obligatoires sont marqués avec (*) </b-alert>
                      <div class="row">
                            <!-- <div class="col-lg-3">
                              <div class="form-group">
                                <label for="formrow-inputCity">N° ODS *</label>
                                <input type="text"  class="form-control" required v-model="ODS_info.ID_ODS" placeholder="152/2021"/>
                              </div>
                            </div> -->

                            <div class="col-lg-4">
                              <div class="form-group">
                                <label for="formrow-inputCity"
                                  >Nom de la Base *</label
                                >
                                <select class="form-control" v-model="selectedBdv">
                                    <option selected value="0">Selectionner une Base de vie</option>
                                    <option v-for="base in bases" :key="base.value" :value="base.value"> {{base.text}} </option>
                                </select>
                              </div>
                            </div>

                            <div class="col-lg-4">
                              <div class="form-group">
                                <label for="formrow-password-input">N° Contrat</label>
                                <input type="text"  class="form-control" v-model="ODS_info.ID_CTT" placeholder="452/A2/2021"/>
                              </div>
                            </div>
                            <div class="col-lg-4">
                                <label for="">Prestataire *</label>
                                <select class="form-control" v-model="ODS_info.prestataire">
                                    <option value="0">Selectionner le Prestataire</option>
                                    <option v-for="prest in prests" :key="prest.value" :value="prest.value">{{prest.text}}</option>
                                </select>
                            </div>
                        </div>
                        
                        <div class="row">
                            <div class="col-lg-4">
                                <label for="">Date ODS</label>
                                <input type="date" class="form-control" v-model="ODS_info.date_ODS">
                            </div>
                            <div class="col-lg-4">
                                <label for="">Date execution</label>
                                <input type="date" class="form-control" v-model="ODS_info.date_exec_ODS">
                            </div>
                            <div class="col-lg-4">
                                <label for="">Type de Periode *</label>
                                <select class="form-control" v-model="ODS_info.period">
                                    <option value="0">Selectionner la periode</option>
                                    <option v-for="period in periods" :key="period.value" :value="period.value">{{period.label}}</option>
                                </select>
                            </div>
                        </div>
                        <!-- <div class="row">
                            <div class="col-lg-2 mt-3" v-for="(swit) in switchers" :key="swit.label">
                                <label for="">{{swit.label}}</label><br>
                                <switches v-model="ODS_info[swit.model]" type-bold="true" :color="swit.color" class="ms-1"></switches>
                            </div>
                        </div> -->

                        <div class="row">
                            <div class="col-md-12">
                              <div class="form-group">
                                <label for="formrow-inputCity">Commentaires *</label>
                                <textarea
                                  v-model="ODS_info.comments"
                                  class="form-control"
                                  cols="30"
                                  rows="1" 
                                  required
                                ></textarea>
                              </div>
                            </div>
                        </div>

                      <!-- end row -->
                    </tab-content>
                    <!-- <tab-content title="Affectation des emplacements" icon="fas fa-map-signs">
                        <div class="row mt-2" v-for="(field,i) in fields" :key="i">
                          <div class="col-lg-2">
                            <label for="">Liste des Regions</label>
                            <select class="form-control" v-model="field.region" name="" id=""></select>
                          </div>
                          <div class="col-lg-2">
                            <label for="">Liste des Sites</label>
                            <select class="form-control" v-model="field.site" name="" id=""></select>
                          </div>
                          <div class="col-lg-2">
                            <label for="">Liste des Zones</label>
                            <select class="form-control" v-model="field.zone" name="" id=""></select>
                          </div>
                          <div class="col-lg-2">
                            <label for="">Liste des Blocs</label>
                            <select class="form-control" v-model="field.bloc" name="" id=""></select>
                          </div>
                          <div class="col-lg-2 mt-4">
                              <button class="btn btn-danger" type="button" @click="deleteRow(i)"><i class="fas fa-trash-alt"></i> Supprimer</button>
                          </div>
                        </div>
                        <div class="row mt-3">
                          <div class="col text-right">
                              <button class="btn btn-info" type="button" @click="AddformData"><i class="fas fa-plus-circle"></i> Ajouter</button>
                          </div>
                          
                        </div>
                    </tab-content> -->

                    <tab-content title="Ressources Humaines" icon="fas fa-male">

                    </tab-content>

                    <tab-content title="Ressources Materiels" icon="fas fa-shuttle-van">

                    </tab-content>

                    <tab-content icon="fas fa-flag-checkered" title="Résumé">
                        <div class="row">
                          <div class="col-lg-6">
                            <div class="card">
                              <h4 class="card-title pl-2 mb-4">Informations Contractuels</h4>
                              <table class="table table-nowarp mb-0">
                                <tbody>
                                  <tr>
                                    <th scope="row">Base de Vie</th>
                                    <td> {{ODS_info.Bdv}} </td>
                                  </tr>
                                  <tr>
                                    <th scope="row">N° Contract</th>
                                    <td>{{ODS_info.ID_CTT}}</td>
                                  </tr>
                                  <tr>
                                    <th scope="row">Prestataire</th>
                                    <td>{{ODS_info.prestataire}}</td>
                                  </tr>
                                  <tr>
                                    <th scope="row">Date ODS</th>
                                    <td>{{ODS_info.date_ODS}}</td>
                                  </tr>
                                  <tr>
                                    <th scope="row">Date d'Execution</th>
                                    <td>{{ODS_info.date_exec_ODS}}</td>
                                  </tr>
                                  <tr>
                                    <th scope="row">Type Période</th>
                                    <td>{{ODS_info.period}}</td>
                                  </tr>
                                </tbody>
                              </table>
                            </div>
                          </div>
                        <!-- end col -->
                        </div>
                    </tab-content>
                    
                  </form-wizard>
                </div>
                <!-- end card-body -->
              </div>
          <!-- </b-tab> -->
          <!-- <b-tab v-for="resto in restaurants.restaurants" :key="resto" :title="resto">
              
          </b-tab> -->
          <!-- <b-tab title="Infos Staff">
              <info-staff/>
          </b-tab> -->
          <!-- <b-tab title="Disabled" disabled><p>I'm a disabled tab!</p></b-tab> -->
        <!-- </b-tabs> -->
        <!-- <form-wizard shape="tab" ref="principal" color="#556ee6">
          <tab-content icon="mdi mdi-information-variant" ref="wizard" title="Informations Générales">
              
          </tab-content>
          <tab-content icon="mdi mdi-office-building" title="Infrastructure">
              
          </tab-content>
          <tab-content icon="mdi mdi-account-network" title="Info Staff">
                      
                      
          </tab-content>
        </form-wizard> -->
        
      </div>
    </div>
    <!-- </Layout> -->
</template>
<style>
  .wiz .wizard-navigation .wizard-progress-with-circle{
    top:18px !important;
  }
  .wiz .wizard-navigation .wizard-nav .wizard-icon-circle{
    width: 27px !important;
    height: 27px  !important;
    font-size:12px;
  }
  .wiz .wizard-navigation .wizard-nav .checked {
    color: white;
    background-color: #66cd00 !important;
  }
</style>