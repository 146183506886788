<script>
import Layout from "../../../layouts/main";
import PageHeader from "@/components/page-header";
import appConfig from "@/app.config";
import ODSform from "./components/ODSform";

// import Swal from "sweetalert2";
// import { required } from "vuelidate/lib/validators";
// import { fetchSettings } from "./api";

export default {
    page: {
        title: "Base de vies de l'entreprise",
        meta: [{ name: "description", content: appConfig.description}],
    },
    // import : { fetchSettings },
    components: { Layout, PageHeader,ODSform},
    data() {
    return {
      tableData: [],
      title: "Entretien & Nettoyage",
      List_ODS: [],
      rows:'',
      active:false,
      selectedRows:[],
      ODS:undefined,
      breakfast:[],
      lunch:[],
      dinner:[],
      items: [
        {
          text: "Catalogue des Services",
          href: "javascript:void(0)",
        },
        {
          text: "Entretien & Nettoyage",
          active: true,
        },
      ],
      totalRows: 1,
      currentPage: 1,
      perPage: 10,
      pageOptions: [10, 25, 50, 100],
      filter: null,
      filterOn: [],
      sortDesc: false,
      sortBy: "name",
      fields: [
        { key: "name", sortable: true },
        { key: "type", sortable: true },
        { key: "classe", sortable: true },
      ],
    };
  },
  mounted(){
    // this.getLifeBases();
  },
  watch:{
      selectedRows(){
        console.log(this.selectedRows);
      },
      ODS:{
          handler(val){
            
            val.ODS_break = this.breakfast;
            val.ODS_lunch = this.lunch;
            val.ODS_dinner = this.dinner;
            
            this.List_ODS.push({
                ID_ODS:val.ID_ODS,
                Bdv:val.Bdv,
                ID_CTT:val.ID_CTT,
                prestataire:val.prestataire,
                date_ODS:val.date_ODS,
                breakfast:val.breakfast,
                lunch:val.lunch,
                dinner:val.dinner,
                others:val.others,
                comments:val.comments,
                ODS_break: val.ODS_break,
                ODS_lunch: val.ODS_lunch,
                ODS_dinner: val.ODS_break,
            });
            
            console.log(this.List_ODS);
          }
          
          
      }
  },
  methods:{
    getODS(){
      this.$http.get('/base/lifebases/index')
      .then(response => {
          this.bases = response.data;
          this.selectedRows.push(response.data[0]);
      })
      .catch(error => console.log(error))
    },
    refreshODS(){
      this.getODS();
    }
  }
    
}
</script>
<template>
    <Layout>
    <PageHeader :title="title" :items="items" />
    <div class="row">
      <div class="col-12">
        <div class="accordion mb-2" role="tablist">
          <b-card no-body class="mb-1">
            <b-card-header header-tag="header" class="p-1" role="tab">
              <b-button block @click="active = !active" style="background:#e5e9f0; color:black !important; border:none !important;">
                <div class="row">
                  <div class="col text-left mt-1"> <i class="mdi mdi-format-list-bulleted"></i> Liste Ordres de Service (Entretien & Nettoyage)</div>
                  <div class="col text-right">
                    <i v-if="active" class="mdi mdi-chevron-up font-size-18"></i> 
                    <i v-else class="mdi mdi-chevron-down font-size-18"></i> 
                  </div>
                </div>
              </b-button>
            </b-card-header>
            <b-collapse id="accordion-1" :visible="active" accordion="my-accordion" role="tabpanel">
              <b-card-body class="shadow-lg">
              <div class="table-responsive mb-0 shadow">
                <!-- <table class="table table-centered table-nowrap"> -->
                  <v-table
                    class="table table-hover"
                    :data="List_ODS"
                    selectionMode="single"
                    selectedClass="table-info"
                    @selectionChanged="selectedRows = $event"
                  >
                  <thead slot="head" class="thead-light">
                    <tr>
                      <th>N° ODS</th>
                      <th>Base de Vie</th>
                      <th>N° Contrat</th>
                      <th>Prestataire</th>
                      <th>Date ODS</th>
                      <th>Date Exécution</th>
                      <!-- <th>Actions</th> -->
                    </tr>
                  </thead>
                  <tbody slot="body" slot-scope="{displayData}">
                    <v-tr v-for="row in displayData" :key="row.id" :row="row">
                      <td>
                        <!-- <router-link
                          :to="{
                            name: 'settings.lifebases.show',
                            params: { uid: row.uuid },
                          }"
                          >{{ row.name }}</router-link
                        > -->
                        {{ row.ID_ODS }}
                      </td>
                      <td>{{ row.Bdv }}</td>
                      <td>{{ row.ID_CTT }}</td>
                      <td>{{ row.prestataire }}</td>
                      <td>{{ row.date_ODS }}</td>
                      <td>{{ row.date_execODS }}</td>
                      <!-- <td v-if="row.breakfast" class="text-success"><i  class="fas fa-check-circle text-success"></i></td>
                      <td v-else><i class="fas fa-times-circle text-danger"></i></td>
                      <td v-if="row.lunch" class="text-success"><i  class="fas fa-check-circle text-success"></i></td>
                      <td v-else><i class="fas fa-times-circle text-danger"></i></td>
                      <td v-if="row.dinner" class="text-success"><i  class="fas fa-check-circle text-success"></i></td>
                      <td v-else><i class="fas fa-times-circle text-danger"></i></td> -->
                      <!-- <td>
                        <router-link
                          :to="{
                            name: 'settings.lifebases.show',
                            params: { uid: row.uuid },
                          }"
                          class="mr-3 text-primary"
                          data-toggle="tooltip"
                          data-placement="top"
                          title="Détails"
                          data-original-title="Edit"
                          ><i class="bx bx-cog font-size-18"></i
                        ></router-link>
                        
                        <router-link
                          :to="{
                            name: 'settings.baselifes.edit',
                            params: { uid: row.uuid },
                          }"
                          class="mr-3 text-primary"
                          data-toggle="tooltip"
                          data-placement="top"
                          title="Modifier"
                          data-original-title="Edit"
                          ><i class="mdi mdi-pencil font-size-18"></i
                        ></router-link>

                        <a
                          @click="deleteRole(row.name, row.uuid)"
                          class="text-danger"
                          data-toggle="tooltip"
                          data-placement="top"
                          title="Supprimer"
                          data-original-title="Delete"
                          ><i class="mdi mdi-close font-size-18"></i
                        ></a>
                      </td> -->
                    </v-tr>
                  </tbody>
                </v-table>
              </div>
              </b-card-body>
            </b-collapse>
          </b-card>
        </div>

        <div class="card mt-3">
            <ODSform :base="selectedRows" @odsvalues="ODS = $event"/>
        </div>
      </div>
    </div>
    </Layout>
</template>